import Component from '@glimmer/component'

export default class UiDividerComponent extends Component {
  get my() {
    return this.args.my || '2'
  }

  get color() {
    return this.args.color || 'ui-gray-80'
  }
}
