import classic from 'ember-classic-decorator';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';
import Route from '@ember/routing/route';
import resetScroll from 'financeit-components/utils/reset-scroll'
import RSVP, { Promise } from 'rsvp';

@classic
class PaymentPlanApplicationFormRoute extends Route {
  @service
  store;

  @service
  intl;

  @service
  appMonitoring;

  init() {
    super.init(...arguments);
    this.on('routeWillChange', this.willTransition.bind(this))
    this.on('routeDidChange', this.didTransition.bind(this))
  }

  activate() {
    document.querySelector('html').classList.add('disable-legacy-theming');
    const applicationFormController = this.controllerFor('payment-plan.application-form');
    if (applicationFormController.get('showSpinner')) { return applicationFormController.set('showSpinner', false); }
    applicationFormController.startLoggingPageUnload();
    resetScroll()
  }

  deactivate() {
    const applicationFormController = this.controllerFor('payment-plan.application-form');
    applicationFormController.stopLoggingPageUnload();
    return document.querySelector('html').classList.remove('disable-legacy-theming');
  }

  beforeModel() {
    return new Promise(resolve => later(() => resolve(), 1))
  }

  model(params) {
    if ((params != null) && (params.inviteId != null)) {
      return this.store.queryRecord('direct-invite', { invite_id: params.inviteId }).then(invite => RSVP.hash({
        invite
      }));
    }
  }

  afterModel() {
    this.controllerFor('partner').setTheme();
  }

  setupController(controller, model) {
    if (!controller.get('partner.isBigBox')) {
      // eslint-disable-next-line ember/no-jquery
      $(document).attr('title', this.intl.t('application_form.loan_application'));
    }

    this.appMonitoring.setUserProperty('isBigBox', controller.get('partner.isBigBox'))

    controller.validateProvince();
    controller.trackSelectChanges();
    controller.set('startApplicationTime', Date.now());
    if ((model != null) && (model.invite != null)) {
      controller.set('directInvite', model.invite);
    }

    return controller.warnOnFormUnload();
  }

  @action
  didTransition() {
    this.controller.set('loadingStatus', false);
    return this.controller.initInviteVariables();
  }

  @action
  willTransition(transition) {
    this.controller.set('loadingStatus', true);

    if (!this.controller.get('inviteExpired') && this.controller.formStarted() && !transition.targetName.match('portal') && !transition.targetName.match('redirect-to-apply')) {
      this.controller.logPageUnload()
      const navigationConfirmed = confirm(this.intl.t('application_form.navigation_warn'));

      if (navigationConfirmed) {
        // eslint-disable-next-line ember/no-jquery
        return $(window).off('beforeunload');
      } else {
        this.controller.set('loadingStatus', false);
        transition.abort();
        return window.history.forward();
      }
    }
  }
}

export default PaymentPlanApplicationFormRoute;
