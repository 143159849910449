import { action } from '@ember/object';
import { inject as service } from '@ember/service'
import * as UiModalUtils from 'tag/utils/ui-modal'

import PortalOffersController from '../offers';

export default class PortalOffersOfferController extends PortalOffersController {
  @service intl

  get subventionType() {
    return this.subvention?.promo?.type;
  }

  get subventionDeferralPeriod() {
    return this.subvention?.promo?.amortization_offset;
  }

  get partner() {
    return this.model.quote.partner;
  }

  get vertical() {
    return this.model.loan.vertical;
  }

  get purchaseAmount() {
    return this.model.loan.requestedAmount;
  }

  get headingText() {
    if (this.model.loan.isShoppingPass) {
      return this.intl.t("apply.calculator.heading_principal_only", { duration: this.model.loan.shoppingPassDuration })
    } else if (this.model.loan.isCreditTopUp) {
      return this.intl.t("apply.calculator.heading_edit_loan")
    } else {
      return this.intl.t("apply.calculator.heading")
    }
  }

  get contactMerchantText() {
    if (this.model.loan.isCreditTopUp) {
      return ""
    }
    return this.intl.t("calculator.learn_more_contact_merchant", { merchant_name: this.partner.get('businessName') })
  }

  @action
  openLearnMoreModal() {
    UiModalUtils.showModal('learn-more-modal')
  }

  @action
  showMobileQuoteModal() {
    UiModalUtils.showModal('quote-modal');
  }
}
