import classic from 'ember-classic-decorator';
import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import { isEmpty } from '@ember/utils'

@classic
class PortalPaymentReviewIndexRoute extends Route {
  queryParams = {
    makePaymentType: {
      refreshModel: false
    }
  }

  @service
  intl;
  @service router

  async model() {
    const {changeset, loan, payment} = this.modelFor('portal.payment');
    const paymentData = await payment.previewMakePayment({
      loan: loan.serialize(),
      paymentType: changeset.get('makePaymentType'),
      amount: changeset.get('makePaymentAmount'),
      payment_method: changeset.get('paymentMethod'),
      login_key: loan.get('loginKey')
    });

    if (isEmpty(paymentData.errors)){
      return {
        payment,
        changeset,
        loginKey: loan.get('loginKey'),
        towardPrincipal: paymentData.principal,
        towardInterest: paymentData.interest,
        bankAccount: paymentData.bank_account,
        remainingBalance: paymentData.remaining_balance,
        towardLateFees: paymentData.late_fees,
        towardDischargeFee: paymentData.discharge_fee
      }
    } else {
      changeset.pushErrors('makePaymentAmount', paymentData.errors);
      return this.router.transitionTo('portal.payment.make-payment');
    }
  }

  beforeModel() {
    document.title = this.intl.t('payment_options.review.title');
  }
}

export default PortalPaymentReviewIndexRoute;
