import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { formatAsCurrency } from 'tag/utils/number-formatters'
import { isPresent } from '@ember/utils';
import { debounce } from '@ember/runloop';

export default class CalculatorComponent extends Component {
  @service intl;
  @service snowplow;
  @service router
  @tracked isWaitingForCalculation = false;
  @tracked errorMessage = null;
  @tracked showCalculations = false;

  constructor() {
    super(...arguments);
    if (this.args.quote?.useMaxAmortization) {
      this.setMaxAmort();
    }

    if(isPresent(this.args.purchaseAmount) && isPresent(this.args.quote?.calculations)) {
      this.showCalculations = true;
    }
  }

  get paymentFrequencyOptions() {
    const availablePaymentFrequencies = this.args.quote.get('partner.availablePaymentFrequencies') ?? [];

    return availablePaymentFrequencies.map(frequency => {
      return {
        value: frequency,
        label: this.intl.t(`apply.payment_frequency.${frequency}`).toUpperCase()
      };
    });
  }

  get amortizationOptions() {
    let amortizations = [];
    if (isPresent(this.args.subvention) && !this.args.subvention.isStandardProgram) {
      amortizations = this.args.subvention.get('subventionAmortizations')
      if (this.args.subvention.get('isNoPayments')) {
        const offset = this.args.subvention.get('amortizationOffset')
        amortizations = amortizations.map(e => e - offset)
      }
    } else {
      amortizations = this.args.quote.get('partner.applicableAmortizations') ?? [];
    }

    if (isPresent(this.args.quote.get('calculations.max_amort'))) {
      amortizations = amortizations.filter(amort => amort <= this.args.quote.get('calculations.max_amort'));
    }

    return amortizations.reduce((obj, a) => {
      return { ...obj, [a]: this.intl.t('calculator.amortization_option', { period: a }) };
    }, {});
  }

  maxAmortOption() {
    const keys = Object.keys(this.amortizationOptions);
    return keys.sort((a, b) => a - b)[keys.length - 1];
  }

  setMaxAmort() {
    this.args.quote.set('amortization', this.maxAmortOption());
  }

  get isAmountInRange() {
    const enteredAmount = parseFloat(this.args.quote.get('amount'), 10);
    return enteredAmount >= this.minAmount && enteredAmount <= this.maxAmount;
  }

  get invalidAmount() {
    return isPresent(this.args.quote.get('amount')) && !this.isWaitingForCalculation && !this.isAmountInRange;
  }

  get minAmount() {
    return this.args.minAmount ?? this.args.quote.get('vertical.amountRange.min');
  }

  get maxAmount() {
    return this.args.maxAmount ?? this.args.quote.get('vertical.amountRange.max');
  }

  get showLearnMoreLink() {
    return typeof this.args.showLearnMoreModal === 'function';
  }

  async calculate() {
    this.isWaitingForCalculation = true;
    if (this.isAmountInRange) {
      const quote = this.args.quote;

      if (this.args.useMaxAmortization) {
        this.args.quote.set('useMaxAmortization', true);
      }

      const response = await quote.calculate({
        subvention_id: quote.subvention.get('id'),
        ...quote.serialize()
      });
      quote.set('calculations', response);
      if (this.args.quote.get('useMaxAmortization') || (this.args.quote.get('amortization') > this.args.quote.get('calculations.max_amort'))) {
        this.setMaxAmort();
      }
      this.showCalculations = true;
    } else {
      this.showCalculations = false;
      if (isPresent(this.args.quote.get('amount'))) {
        this.errorMessage = this.intl.t('calculator.invalid_amount', {
          min: formatAsCurrency(this.minAmount, { precision: 0 }),
          max: formatAsCurrency(this.maxAmount, { precision: 0 })
        });
      }
    }
    this.isWaitingForCalculation = false;
  }

  @action
  enableFormTracking() {
    if (this.args.snowplowTracked) {
      this.snowplow.enableFormTracking();
    }
  }

  @action
  onAmountChange() {
    this.errorMessage = null;
    this.isWaitingForCalculation = true;
    debounce(this, this.calculate, 500);
  }

  @action
  onInputChange() {
    this.calculate();
  }

  @action
  onAmortizationChange() {
    this.args.quote.set('useMaxAmortization', false);
    this.calculate();
  }

  @action
  onUseMaxAmortChange() {
    if (this.args.quote.get('useMaxAmortization')) {
      this.setMaxAmort();
      this.calculate();
    }
  }

  @action
  async onSubmit(){
    try {
      await this.args.loan.adjust({
        requested_amount: this.args.quote.get('amount'),
      });
      return this.router.transitionTo('portal.hub')
    } catch (error) {
      if (error?.status === '422') {
        // do nothing
      } else {
        throw error
      }
    }
  }

  @action
  showLearnMoreModal() {
    this.args.showLearnMoreModal();
  }

  @action
  showMobileQuoteModal() {
    this.args.showMobileQuoteModal();
  }
}
