import classic from 'ember-classic-decorator'
import { action, computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { alias, readOnly } from '@ember/object/computed'
import { underscore } from '@ember/string'
/* eslint-disable
    ember/use-brace-expansion,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils'
import Controller, { inject as controller } from '@ember/controller'
import * as UiModalUtils from 'tag/utils/ui-modal'

@classic
class PaymentPlanApplyController extends Controller {
  @controller
  subvention

  @controller('partner')
  partnerController

  @readOnly('partnerController.model')
  partner

  @alias('model.directInvite')
  directInvite

  @alias('model.quote')
  quote

  @alias('model.quote.vertical')
  vertical

  @service
  intl

  queryParams = [{ inviteId: "iid", vehicleType: 'vehicle_type', purchaseAmount: 'p', amortization: 'a' }, 'qr', 'slug']
  hasQuoteWithAmount = null
  inviteId = null
  vehicleType = null
  purchaseAmount = null
  amortizaton = null
  loadingStatus = false
  qr=null
  slug=null

  @computed('hasQuoteWithAmount', 'subvention.promo', 'paymentFrequency')
  get marketingCopyAffordable() {
    if (this.hasQuoteWithAmount) {
      return null
    } else {
      const program = this.getProgram()
      return this.subvention.marketingCopyAffordable(program, this.paymentFrequency)
    }
  }

  @computed('paymentFrequency', 'subvention.isBadgePromotion', 'subvention.promo.number_of_payments')
  get promoCardContent() {
    if (!isPresent(this.get('subvention.promo')) || !this.get('subvention.isBadgePromotion')) {
      return null
    }

    const promo = this.get('subvention.promo')
    if (promo.type == 'InterestRateBuyDown') {
      return null
    }

    const vars = {
      frequency: this.intl.t(`apply.payment_frequency.${this.paymentFrequency}`),
      deferral_period: promo.subvention_duration,
      number_of_payments: this.get('subvention.promo.number_of_payments')
    }

    // eslint-disable-next-line ember/no-string-prototype-extensions
    return this.intl.t(`apply.promo.${underscore(promo.type)}.content`, vars)
  }

  @computed('subvention.promo', 'subvention.standard')
  get subventionDisclaimer() {
    const program = this.getProgram()
    return this.subvention.disclaimer(program)
  }

  @computed('subvention.isBadgePromotion', 'hasQuoteWithAmount')
  get showFooterDisclaimer() {
    return !this.get('subvention.isBadgePromotion') && !this.hasQuoteWithAmount
  }

  getProgram() {
    let program
    if (isPresent(this.get('subvention.promo'))) {
      return program = this.get('subvention.promo')
    } else {
      return program = this.get('subvention.standard')
    }
  }

  @computed('quote.hideFees')
  get noAdminFees() {
    return this.get('quote.hideFees')
  }

  @computed('quote.calculations.{amortization,term}')
  get termLessThanAmort() {
    return this.get('quote.calculations.amortization') > this.get('quote.calculations.term')
  }

  @computed('quote.calculations.term')
  get currentTerm() {
    return this.get('quote.calculations.term')
  }

  @computed('quote.calculations')
  get primeDelta() {
    return this.get('quote.calculations').fi_prime_delta * 1
  }

  @computed('quote.calculations')
  get variableResetPeriodYears() {
    return this.get('quote.calculations').variable_reset_period_years
  }

  //####
  //get rid of this once the subvention model is added to direct
  //
  @computed('subvention.promo')
  get subventionDuration() {
    return this.get('subvention.promo').subvention_duration
  }

  @computed('subvention.promo')
  get subventionType() {
    return this.get('subvention.promo').type
  }

  @computed('subvention.promo')
  get subventionRate() {
    return this.get('subvention.promo').rate * 100
  }

  @computed('subvention.promo')
  get loanTerm() {
    return this.get('subvention.promo').loan_term
  }

  @computed('subvention.promo')
  get subventionTerm() {
    return this.get('subvention.promo').term
  }

  @computed('subvention.promo')
  get subventionName() {
    return this.get('subvention.promo').name
  }

  @computed('subvention.promo')
  get subventionDeferralPeriod() {
    return this.get('subvention.promo').amortization_offset
  }

  @computed(
    'subvention.{isBadgePromotion,isBuydownWithDifferentTerm,isSteppedUpSubvention,isEqualPayments,isStandardProgram,promo,specialPromo}', 'loanTerm', 'subventionTerm', 'subventionDeferralPeriod', 'subventionDuration', 'subventionName', 'subventionRate', 'subventionType'
  )
  get quoteSubvention() {
    return {
      subventionDuration: this.subventionDuration,
      subventionType: this.subventionType,
      subventionRate: this.subventionRate,
      subventionTerm: this.subventionTerm,
      loanTerm: this.loanTerm,
      name: this.subventionName,
      subvented: !this.get('subvention.isStandardProgram'),
      isBadgePromotion: this.get('subvention.isBadgePromotion'),
      isEqualPayments: this.subventionType === "SameAsCash",
      isBuydownWithDifferentTerm: this.get('subvention.isBuydownWithDifferentTerm'),
      deferralPeriod: this.subventionDeferralPeriod,
      specialPromo: this.get('subvention.specialPromo'),
      promo: this.get('subvention.promo'),
      isSteppedUpSubvention: this.get('subvention.isSteppedUpSubvention'),
    }
  }

  @computed('partner.defaultPaymentFrequency', 'quote.paymentFrequency')
  get paymentFrequency() {
    if (isPresent(this.quote)) { return this.get('quote.paymentFrequency') } else { return this.get('partner.defaultPaymentFrequency') }
  }

  @computed('hasQuoteWithAmount')
  get showCalculator() {
    return !this.hasQuoteWithAmount
  }

  @action
  showLearnMoreModal() {
    UiModalUtils.showModal('learn-more-modal')
  }

  @action
  showMobileQuoteModal() {
    UiModalUtils.showModal('quote-modal')
  }
}

export default PaymentPlanApplyController
