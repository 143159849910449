import classic from 'ember-classic-decorator'
import { action, computed } from '@ember/object'
import { inject as service } from '@ember/service'
import { alias, readOnly } from '@ember/object/computed'
/* eslint-disable
    ember/no-jquery,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isPresent } from '@ember/utils'
import { schedule, next, later } from '@ember/runloop'
import Controller, { inject as controller } from '@ember/controller'
import * as UiModalUtils from 'tag/utils/ui-modal'
import { serializeForCreate } from 'financeit-components/utils/prepare-borrower-for-submit'
import { applyErrors } from 'financeit-components/utils/model-helpers'
import secureFetch from 'direct/utils/secure-fetch'

@classic
class PaymentPlanApplicationFormController extends Controller {
  @service
  router

  @service
  snowplow

  @controller('financeit')
  financeitController

  @readOnly('financeitController')
  financeit

  @controller('partner')
  partnerController

  @readOnly('partnerController.model')
  partner

  @controller('borrower')
  borrowerController

  @alias('borrowerController.model')
  borrower

  @controller
  subvention

  @controller
  session

  @service
  intl

  @service
  homeDepotAnalytics

  @service store

  queryParams = [{ inviteId: 'iid', purchaseAmount: 'p', paymentFrequency: 'pf', amortization: 'a' }, 'vehicleType', 'submitterId', 'qr', 'slug']
  submitTimeoutMs = 180 * 1000
  applicationSubmittedAt = null
  startApplicationTime = null
  baseError = null
  holdingForBureau = false
  inviteId = null
  inviteExpired = false
  purchaseAmount = null
  paymentFrequency = null
  amortization = null
  showAuthError = false
  selectFieldsChanged = false
  vehicleType = null
  loadingStatus = false
  showProgressBar = false
  shouldCompleteProgressBar = false
  showSpinner = false
  submitterId = null
  qr=null
  slug=null

  validateProvince() {
    return schedule('afterRender', this, () => {
      return $('select[name="province"]').change(e => {
        $(e.target).closest('.info-bit--input').removeClass('has-errors')
        return $(e.target).siblings('span.custom').removeClass('error-message').addClass('hint').text('')
      })
    })
  }

  @computed('directInvite.phoneNumber')
  get cleanedPhoneNumber() {
    const phone = this.get('directInvite.phoneNumber')
    if (isPresent(phone)) {
      return phone.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})/, "($1)-$2-$3")
    } else {
      return null
    }
  }

  initInviteVariables() {
    return schedule('afterRender', this, () => {
      this.borrower.setProperties({
        email: this.get('directInvite.email'),
        firstName: this.get('directInvite.firstName'),
        lastName: this.get('directInvite.lastName'),
        applicationType: this.get('directInvite.sendingMethod'),
        mobilePhoneNumber: this.cleanedPhoneNumber,
        spendingEstimate: this.get('directInvite.purchaseAmount'),
        address: this.get('directInvite.address'),
        unitNumber: this.get('directInvite.unitNumber'),
        city: this.get('directInvite.city'),
        province: this.get('directInvite.province'),
        postalCode: this.get('directInvite.postalCode'),
      })

      return $('input[name$=address]').focus(function() {
        return $(this).attr('autocomplete', false)
      })
    })
  }

  holdForBureau() {
    const qqSimulateTimeoutCheckbox = document.querySelector('#qq_simulate_timeout')
    if (qqSimulateTimeoutCheckbox?.checked) {
      return this.showGeneralError()
    }

    return later(this, () => {
      return $.ajax({
        type: 'GET',
        url: `/${this.intl.language}/api/v3/direct/loans/${this.get('loan.id')}/hold_for_decision`,
        data
      }).then(data => {
        if (isPresent(data)) {
          this.set("shouldCompleteProgressBar", true)

          return later(this, () => {
            this.set("showProgressBar", false)
            this.set("shouldCompleteProgressBar", false)
            this.set('session.loggedIn', true)

            this.store.pushPayload('loan', { loan: data.loan })
            this.set('loan', this.store.peekRecord('loan', data.loan.id))

            if (this.get('partner.isBigBox')) {
              this.homeDepotAnalytics.trackStageOneLoanEvent(
                data.loan.id, this.homeDepotAnalytics.applicationSubmittedEventName)
            }

            this.borrower.deleteRecord()
            if (data.status) {
              this.store.push(this.store.normalize('borrower', data.borrower))
              this.set('borrower', this.store.peekRecord('borrower', data.borrower.id))

              if (this.get('partner.isBigBox')) {
                this.homeDepotAnalytics.trackStageOneLoanEvent(
                  data.loan.id, this.homeDepotAnalytics.applicationDeclinedEventName)
              }

              if (data.status === 'soft-decline') {
                if (this.loan.noScoreBureauWithNoHardPull) {
                  return this.router.transitionTo('portal.stage-two-loan-application-form', this.loan.loginKey)
                } else {
                  return this.router.transitionTo('portal.soft-decline', this.loan.loginKey)
                }
              } else if (data.status === 'hard-decline') {
                if (this.redirectToStageTwoAfterDecline(data)) {
                  return this.router.transitionTo('portal.stage-two-loan-application-form', this.loan.loginKey)
                } else {
                  return this.router.transitionTo('portal.hard-decline', this.loan.loginKey)
                }
              }
            } else {
              this.store.push(this.store.normalize('borrower', data.borrowers[0]))
              this.set('borrower', this.store.peekRecord('borrower', data.borrowers[0].id))

              if (this.get('partner.isBigBox')) {
                this.homeDepotAnalytics.trackStageOneLoanEvent(
                  data.loan.id, this.homeDepotAnalytics.applicationApprovedEventName)
              }
              if (this.loan.isPrequalified) {
                if (this.loan.isCounterOffer) {
                  return this.router.transitionTo('portal.offers.offer-approved', this.loan.loginKey)
                }

                return this.router.transitionTo('portal.stage-two-loan-application-form', this.loan.loginKey)
              }

              return this.router.transitionTo('portal.offers.offer-approved', this.get('loan.loginKey'))
            }

          }, 500)
        } else {
          if (Date.now() > this.applicationSubmittedAt + this.submitTimeoutMs) {
            return this.showGeneralError()
          } else {
            return this.holdForBureau()
          }
        }
      }, _jqXHR => {
        return this.showGeneralError()
      })
    }, 5000)
  }

  redirectToStageTwoAfterDecline(data) {
    return !data.borrower.credit_file_locked && this.loan.isPulled && !this.loan.isHardPulled
    && !this.loan.eligibleForSinResubmit && !this.get('partner.isBigBox')
  }

  showGeneralError() {
    this.set('showSpinner', false)
    this.set('showProgressBar', false)
    return UiModalUtils.showModal('general-error-modal')
  }

  formStarted() {
    if (this.selectFieldsChanged) { return true }
    const $inputs = $('form input:visible:not([type="checkbox"])')
    for (let input of Array.from($inputs)) {
      if ($(input).val()) { return true }
    }
    return false
  }

  trackSelectChanges() {
    this.set('selectFieldsChanged', false)
    return next(this, function() {
      return $('select').on('change', () => {
        return this.set('selectFieldsChanged', true)
      })
    })
  }

  warnOnFormUnload() {
    return $(window).on('beforeunload', () => {
      if (this.formStarted()) {
        return this.intl.t('application_form.navigation_warn')
      }
    })
  }

  startLoggingPageUnload() {
    this.set('logPageUnloadReference', this.logPageUnload.bind(this))
    window.addEventListener('beforeunload', this.logPageUnloadReference)
  }

  stopLoggingPageUnload() {
    window.removeEventListener('beforeunload', this.logPageUnloadReference)
  }

  logPageUnload() {
    if (this.showSpinner) {
      this.snowplow.snowplow('trackStructEvent', {
        category: 'FinanceIt::Partner::ApplicationFormController',
        action: 'page_is_refreshed_or_closed_or_back_button_clicked',
      })
    }
  }

  @action
  async onSubmit() {
    this.applicationSubmittedAt = Date.now()

    let qq_options
    this.set('showSpinner', true)
    this.set('showProgressBar', true)

    $(window).off('beforeunload')
    this.set('loadingStatus', true)

    if ($('.quick_qa_container').length) {
      qq_options = QuickQA.get_qq_options_hash()
    }
    this.set('borrower.qqOptions', qq_options)

    // OR-4399 E-sign checkbox has been eliminated set value on model to '1' for implicit acceptance before submitting
    this.borrower.set('esignConsentReceived', '1')
    // OR-4400 phoneType has been removed from application form, but API still requires it set value on model to 'Mobile' before submitting
    this.borrower.set('phoneType', 'Mobile')

    const data = serializeForCreate({
      model: this.borrower,
      sessionKey: this.session.get('key'),
      purchaseAmount: this.purchaseAmount,
      paymentFrequency: this.paymentFrequency,
      amortization: this.amortization,
      amountIsFixed: this.amountIsFixed,
    })

    if (isPresent(this.inviteId)) {
      data['invite_id'] = this.inviteId
    }
    if (isPresent(this.vehicleType)) {
      data['borrower']['vehicle_type'] = this.vehicleType
    }
    if (isPresent(this.qr)) {
      data['qr'] = this.qr
    }
    if (isPresent(this.slug)) {
      data['slug'] = this.slug
    }
    if (isPresent(this.submitterId)) {
      data['submitter_id'] = this.submitterId
    }

    $('input, select, textarea').blur()
    $('.error-message.custom')
      .removeClass('error-message')
      .addClass('hint')
      .text('')

    try {
      const res = await secureFetch(
        `/${this.intl.language}/api/v3/direct/borrowers`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      )

      if (!res.ok) {
        const json = await res.json()
        const errors = this.store.adapterFor('loan').fetchError(json.errors)

        if (errors.inviteStatus === 'expired') {
          this.set('inviteExpired', true)
          this.router.transitionTo('payment-plan.invite-expired')
          return
        }

        if (isPresent(errors.base)) {
          this.set('baseError', errors.base[0])
        } else {
          this.set('baseError', '')
        }

        if (!this.borrower.get('isValid')) {
          // clear old errors
          this.borrower.get('errors').clear()
        }

        // Setting new errors
        applyErrors(this.borrower, errors)
        this.set('showSpinner', false)
        this.set('showProgressBar', false)
        this.set('loadingStatus', false)
      } else {
        const data = await res.json()
        this.store.pushPayload('loan', { loan: data.loan })
        this.set('loan', this.store.peekRecord('loan', data.loan.id))
        this.set('holdingForBureau', true)

        return this.holdForBureau()
      }
    } catch (error) {
      this.set('showSpinner', false)
      this.set('showProgressBar', false)
      this.set('loadingStatus', false)
    }
  }
}

export default PaymentPlanApplicationFormController
