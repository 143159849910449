import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'direct/config/environment';
import { removeClass } from 'financeit-components/utils/dom-node-helpers'
// eslint-disable-next-line no-unused-vars
import EmString from 'financeit-components/utils/ember_string';


class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  ready() {
    removeClass('.ember-loader', 'active');

    (function(window, fetch) {
      window.fetchActive = 0;
      window.fetch = function() {
          window.fetchActive++;

          return fetch.apply(undefined, arguments)
              .then(res => {
                  window.fetchActive = Math.max(0, window.fetchActive - 1)
                  return res
              })
              .catch(err => {
                  window.fetchActive = Math.max(0, window.fetchActive - 1)
                  throw err
              })
      }
    })(window, fetch)
  }
}

loadInitializers(App, config.modulePrefix);

export default App;
