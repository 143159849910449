import Helper from '@ember/component/helper'
import { service } from '@ember/service'

export default class ChangeLangHelper extends Helper {
  @service intl

  compute() {
    if (this.intl.language === 'en') {
      return '/en/changelang/fr'
    } else {
      return '/fr/changelang/en'
    }
  }
}
