import PortalHubController from '../hub'
import { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { inject as controller } from '@ember/controller'
import { action } from '@ember/object'
import * as UiModalUtils from 'tag/utils/ui-modal'

export default class extends PortalHubController {
  @service intl
  @service router
  @tracked isSubmitting = false

  paymentProtectionModalId = 'payment-protection-modal'
  aprModalId = 'apr-modal'
  paymentLowerModalId = 'payment-lower-modal'

  get totalPaymentAmount() {
    return this.loan.paymentAmount + this.loan.insurancePaymentAmount
  }

  get totalSubventedPaymentAmount() {
    return this.loan.subventedPaymentAmount + this.loan.subventedInsurancePaymentAmount
  }

  get zeroInsuranceRateDiscount() {
    if (this.model.insuranceOffer.insuranceDiscount === 0) {
      return true
    }
    if (this.subvention.isSteppedUpSubvention) {
      // probably doesnt ever happen
      return this.subvention.subventionRate === 0 && this.loan.rate === 0
    }
    return this.loan.postDeferralInterestRate === 0
  }

  get higherInsuredApr() {
    return this.model.insuranceOffer.potentialInsuredApr > this.loan.annualPercentageRate
  }

  @controller('portal.hub')
  hubController

  @action
  showModal(id) {
    UiModalUtils.showModal(id)
  }

  @action
  closeModal(id) {
    UiModalUtils.closeModal(id)
  }

  @action
  async submitInsuranceOfferDecisionStep(addPaymentProtection) {
    this.isSubmitting = true
    let requestData = {
      login_key: this.loan.loginKey,
      add_payment_protection: addPaymentProtection
    }
    if (addPaymentProtection) {
      requestData = {
        ...requestData,
        ...{
          full_time_employment_acknowledgement: this.borrower.fullTimeEmploymentAcknowledgement,
          treaty_number: this.borrower.treatyNumber
        }
      }
    }
    const responseData = await this.model.loanStep.submit(requestData)
    await this.store.findRecord('loan', this.loan.id, { reload: true })
    this.isSubmitting = false
    this.afterSubmitStep(responseData)
  }
}
