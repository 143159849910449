import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @id={{@modalId}} as |m|>\n  <m.header @title={{t \"insurance.apr_modal.title\"}} />\n  <m.content>\n    {{#if @higherApr}}\n      <div class=\"tw-mb-3\">\n        <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_higher_q\"}} @textSize=\"sm\" @bold={{true}} />\n        <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_higher_a\"}} @textSize=\"sm\" />\n      </div>\n    {{/if}}\n    <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_difference_q\"}} @textSize=\"sm\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_difference_a\" frequency=@frequency}} @textSize=\"sm\" />\n  </m.content>\n  <m.footer>\n    <UiButton @kind=\"primary\" @text={{t \"insurance.close\"}} @onClick={{this.closeModal}} @fullWidthMobile={{true}} />\n  </m.footer>\n</UiModal>\n", {"contents":"<UiModal @id={{@modalId}} as |m|>\n  <m.header @title={{t \"insurance.apr_modal.title\"}} />\n  <m.content>\n    {{#if @higherApr}}\n      <div class=\"tw-mb-3\">\n        <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_higher_q\"}} @textSize=\"sm\" @bold={{true}} />\n        <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_higher_a\"}} @textSize=\"sm\" />\n      </div>\n    {{/if}}\n    <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_difference_q\"}} @textSize=\"sm\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.apr_modal.apr_difference_a\" frequency=@frequency}} @textSize=\"sm\" />\n  </m.content>\n  <m.footer>\n    <UiButton @kind=\"primary\" @text={{t \"insurance.close\"}} @onClick={{this.closeModal}} @fullWidthMobile={{true}} />\n  </m.footer>\n</UiModal>\n","moduleName":"direct/components/insurance/apr-modal.hbs","parseOptions":{"srcName":"direct/components/insurance/apr-modal.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class AprModalComponent extends Component {
  @action
  closeModal() {
    closeModal(this.args.modalId)
  }
}
