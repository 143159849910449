import FormFieldComponent from './ui-form-field'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import { isPresent } from '@ember/utils'
import uiFormFieldValue from 'tag/decorators/ui-form-field-value'

@uiFormFieldValue
export default class UiDateTimePickerComponent extends FormFieldComponent {
  @service intl

  defaultHour = 12
  defaultMinute = 0

  get altInput() {
    if (isPresent(this.args.altInput)) {
      return this.args.altInput
    }

    return true
  }

  get languageCode() {
    if (this.args.languageCode) {
      return this.args.languageCode
    }

    const locale = this.intl.primaryLocale
    const languageCode = locale.split('-')[0]
    return languageCode
  }

  get placeholder() {
    if (this.args.placeholder) {
      return this.args.placeholder
    }

    let placeholder = this.intl.t('date_time_picker.date_placeholder')
    if (this.args.enableTime) {
      placeholder = this.intl.t('date_time_picker.date_time_placeholder')
    }
    if (this.args.noCalendar) {
      placeholder = this.intl.t('date_time_picker.time_placeholder')
    }
    return placeholder
  }

  get dateValue() {
    // Date value cannot be undefined for `ember-flatpickr`
    if (this.value === undefined) {
      return null
    }

    return this.value
  }

  get altFormat() {
    if (this.args.altFormat) {
      return this.args.altFormat
    }

    const altDateFormat = this.intl.t('flatpickr.alt_format.date')
    const altTimeFormat = this.intl.t('flatpickr.alt_format.time')

    if (this.args.enableTime && this.args.noCalendar) {
      return altTimeFormat
    } else if (!this.args.enableTime && !this.args.noCalendar) {
      return altDateFormat
    }

    return `${altDateFormat} ${altTimeFormat}`
  }

  get dateFormat() {
    const dateFormat = this.intl.t('flatpickr.date_format.date')
    const timeFormat = this.intl.t('flatpickr.date_format.time')

    if (this.args.enableTime && this.args.noCalendar) {
      return timeFormat
    } else if (!this.args.enableTime && !this.args.noCalendar) {
      return dateFormat
    }

    return `${dateFormat} ${timeFormat}`
  }

  get disableMobile() {
    if (isPresent(this.args.disableMobile)) {
      return this.args.disableMobile
    }

    return true
  }

  @action
  onChange(selectedDates) {
    const selectedDate = selectedDates[0]

    if (typeof this.args.onChange === 'function') {
      return this.args.onChange(selectedDate)
    }

    this.value = selectedDate
  }

  // Fix bug where delete keypress while date-picker is open caused visual removal without state change
  @action
  onKeyDown(event) {
    if (event.keyCode === 8) {
      event.stopPropagation()
    }
  }

  @action
  onReady() {
    this.args.onReady?.(...arguments)
  }

  @action
  onOpen(_, dateString) {
    // Fix bug where change event is not fired on default time when timepicker opens
    if (this.args.enableTime && this.args.noCalendar && dateString === '') {
      const date = new Date()
      date.setHours(this.defaultHour, this.defaultMinute)
      if (typeof this.args.onChange === 'function') {
        this.args.onChange(date)
      } else {
        this.value = date
      }
    }

    this.args.onOpen?.(...arguments)
  }

  @action
  onClose() {
    this.args.onClose?.(...arguments)
  }
}
