import { action } from '@ember/object'
import { tracked } from '@glimmer/tracking'
import { inject as service } from '@ember/service'
import Changeset from 'ember-changeset'
import { isPresent, isBlank } from '@ember/utils'
import { formatAsCurrency } from 'tag/utils/number-formatters'
import PortalHubInsuranceController from '../insurance'

export default class extends PortalHubInsuranceController {
  @service store;
  @tracked borrowerChangeset = new Changeset(this.borrower)

  treatyModalId = 'treaty-number-modal'

  get taxExemptionEligible() {
    return this.model.insuranceOffer.insuranceTaxedProvinces.includes(this.borrower.province)
  }

  get treatyNumberEntered() {
    return isPresent(this.borrower.treatyNumber)
  }

  get insuranceChoiceContinueDisabled() {
    return (this.borrowerChangeset.insuranceDecision === 'true' && !this.borrowerChangeset.fullTimeEmploymentAcknowledgement)
      || isBlank(this.borrowerChangeset.insuranceDecision)
      || this.isSubmitting
  }

  get effectiveInsuredRate() {
    return Math.max((this.loan.nonSubventedRate - this.model.insuranceOffer.insuranceDiscount), 0)
  }

  get effectiveInsuredSubventedRate() {
    return Math.max((this.subvention.subventionRate - this.model.insuranceOffer.insuranceDiscount), 0)
  }

  get insuranceLowersPayment() {
    return this.loan.insurancePaymentAmount < 0
  }

  get insuranceLowersSubventedPayment() {
    return this.loan.subventedInsurancePaymentAmount < 0
  }

  get title() {
    if (this.zeroInsuranceRateDiscount) {
      return this.intl.t('insurance.decision.title', {
        cost: formatAsCurrency(this.subvention.isSteppedUpSubvention ? this.loan.subventedInsurancePaymentAmount : this.loan.insurancePaymentAmount, { precision: 2 }),
        for_from: this.subvention.isSteppedUpSubvention ? this.intl.t('insurance.decision.from') : this.intl.t('insurance.decision.for'),
        frequency: this.loan.formattedFrequency,
      })
    } else if (this.subvention.isSteppedUpSubvention) {
      return this.intl.t('insurance.decision.title_lower_rate')
    } else if (this.insuranceLowersPayment) {
      return this.intl.t('insurance.decision.title_lower_rate_and_payment', {
        frequency: this.loan.formattedFrequency,
      })
    } else {
      return this.intl.t('insurance.decision.title_lower_rate_with_cost', {
        cost: formatAsCurrency(this.subvention.isSteppedUpSubvention ? this.loan.subventedInsurancePaymentAmount : this.loan.insurancePaymentAmount, { precision: 2 }),
        for_from: this.subvention.isSteppedUpSubvention ? this.intl.t('insurance.decision.from') : this.intl.t('insurance.decision.for'),
        frequency: this.perPaymentFrequencyText,
      })
    }
  }

  get description() {
    if (this.zeroInsuranceRateDiscount) {
      return this.intl.t('insurance.decision.description')
    } else if (this.insuranceLowersPayment) {
      return this.intl.t('insurance.decision.description_discount_payment_html', {
        original_rate: this.loan.rate,
        new_rate: (this.loan.rate - this.model.insuranceOffer.insuranceDiscount).toFixed(2),
        htmlSafe: true,
        frequency: this.loan.formattedFrequency,
      })
    } else {
      return this.intl.t('insurance.decision.description_discount_html', {
        original_rate: this.loan.rate,
        new_rate: (this.loan.rate - this.model.insuranceOffer.insuranceDiscount).toFixed(2),
        htmlSafe: true,
      })
    }
  }

  @action
  async removeTreatyNumber() {
    this.isSubmitting = true
    await this.borrower.deleteTreatyNumber()
    await this.store.findRecord('loan', this.loan.id, { reload: true })
    this.borrower.treatyNumber = undefined
    this.isSubmitting = false
  }

  @action
  submitInsuranceDecision() {
    if (this.borrowerChangeset.insuranceDecision === 'true') {
      this.borrowerChangeset.execute()
      return this.router.transitionTo('portal.hub.insurance.review')
    } else {
      this.submitInsuranceOfferDecisionStep(false)
    }
  }
}
