import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiButton @kind=\"basic\" @onClick={{fn this.doBackAction @backAction}} @text={{t \"hub.back_general\"}} @icon=\"chevron-left\" data-test-selector=\"step-header-back-btn\" />\n{{#if @breadcrumb}}\n  <UiHeading @kind=\"block\" @my={{5}} @text={{@breadcrumb}} />\n{{/if}}\n{{#if @stepName}}\n  <UiHeading @level={{if @breadcrumb 2 1}} @displayLevel={{3}} @my={{5}} @text={{@stepName}} />\n{{/if}}\n", {"contents":"<UiButton @kind=\"basic\" @onClick={{fn this.doBackAction @backAction}} @text={{t \"hub.back_general\"}} @icon=\"chevron-left\" data-test-selector=\"step-header-back-btn\" />\n{{#if @breadcrumb}}\n  <UiHeading @kind=\"block\" @my={{5}} @text={{@breadcrumb}} />\n{{/if}}\n{{#if @stepName}}\n  <UiHeading @level={{if @breadcrumb 2 1}} @displayLevel={{3}} @my={{5}} @text={{@stepName}} />\n{{/if}}\n","moduleName":"direct/components/step-header.hbs","parseOptions":{"srcName":"direct/components/step-header.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class StepHeaderComponent extends Component {
  @service router
  @service loanStepSlide

  @action back() {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      this.send('slideToHub');
    }
  }

  @action doBackAction(actionString) {
    this[actionString]();
  }

  slideToShoppingDashboard() {
    this.loanStepSlide.to('portal.shopping-pass-dashboard')
  }

  backToFriction() {
    if (typeof this.args.onBackToFrictionClicked === 'function') {
      this.args.onBackToFrictionClicked();
    }
  }

  slideToHub() {
    this.loanStepSlide.to('portal.hub');
  }
}
