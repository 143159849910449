import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UiModal @id={{@modalId}} as |m|>\n  <m.header @title={{t \"insurance.payment_lower_modal.title\"}} />\n  <m.content>\n    <UiText @tagName=\"p\" @text={{t \"insurance.payment_lower_modal.payment_lower_q\" frequency=@frequency}} @textSize=\"sm\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.payment_lower_modal.payment_lower_a\"}} @textSize=\"sm\" />\n  </m.content>\n  <m.footer>\n    <UiButton @kind=\"primary\" @text={{t \"insurance.close\"}} @onClick={{this.closeModal}} @fullWidthMobile={{true}} />\n  </m.footer>\n</UiModal>\n", {"contents":"<UiModal @id={{@modalId}} as |m|>\n  <m.header @title={{t \"insurance.payment_lower_modal.title\"}} />\n  <m.content>\n    <UiText @tagName=\"p\" @text={{t \"insurance.payment_lower_modal.payment_lower_q\" frequency=@frequency}} @textSize=\"sm\" @bold={{true}} />\n    <UiText @tagName=\"p\" @text={{t \"insurance.payment_lower_modal.payment_lower_a\"}} @textSize=\"sm\" />\n  </m.content>\n  <m.footer>\n    <UiButton @kind=\"primary\" @text={{t \"insurance.close\"}} @onClick={{this.closeModal}} @fullWidthMobile={{true}} />\n  </m.footer>\n</UiModal>\n","moduleName":"direct/components/insurance/payment-lower-modal.hbs","parseOptions":{"srcName":"direct/components/insurance/payment-lower-modal.hbs"}});
import Component from '@glimmer/component'
import { action } from '@ember/object'
import { closeModal } from 'tag/utils/ui-modal'

export default class PaymentLowerModalComponent extends Component {
  @action
  closeModal() {
    closeModal(this.args.modalId)
  }
}
