import EmberRouter from '@ember/routing/router'
import ENV from 'direct/config/environment'
import classic from 'ember-classic-decorator';

@classic
export default class Router extends EmberRouter {
  init() {
    if (ENV.environment !== 'test') {
      const locale = window.location.pathname.match(/^\/[^/]+\//)
      this.set('rootURL', locale + 'direct/')
    }

    return super.init(...arguments)
  }

  location = 'auto'
}

Router.map(function() {
  this.route('error');
  this.route('session-expired');
  this.route('cookies-disabled');
  this.route('login'); //deprecated, redirect only
  this.route('reset-password'); //deprecated, redirect only

  this.route('account', { resetNamespace: true }, function() {
    this.route('new');
    this.route('authenticate');
    this.route('email-confirmation');
    this.route('expired-link');
    this.route('login');
    this.route('hdlogin');
    this.route('setup');
    return this.route('reset-password');
  });

  this.route('payment-plan', { path: '/payment-plan/:key', resetNamespace: true }, function() {
    this.route('apply');
    this.route('application-form');
    this.route('redirect-to-apply');
    return this.route('invite-expired');
  });

  this.route('credit-top-up', function() {
    this.route('apply');
    this.route('application-decline')
  });

  this.route('portal', { path: '/portal/:login_key', resetNamespace: true }, function() {
    this.route('stage-two-loan-application-form')
    this.route('profile', function() {
      this.route('edit-personal-details');
      this.route('change-email', function() {
        this.route('confirmation');
      });
      this.route('change-password');
      this.route('edit-employment');
      this.route('preferences');
      this.route('request-deletion')
    });
    this.route('preferences');
    this.route('my-documents');
    this.route('redirect-resign-loan-agreement');
    this.route('redirect-sign-restructure-quebec-loan-agreement');

    this.route('offers', function() {
      this.route('alternative-poi');
      this.route('offer-approved');
      this.route('offer');
      return this.route('contact');
    });

    this.route('confirm-purchase');
    this.route('soft-decline', function() {
      return this.route('add-coborrower');
    });
    this.route('hard-decline');
    this.route('verify');
    this.route('reprompt');

    this.route('shopping-pass-dashboard', function() {
      this.route('calculator');
      return this.route('all-transactions');
    });

    this.route('hub', function() {
      this.route('loan-steps', { path: ':loan_step' });

      this.route('secure-your-account', function() {
        this.route("error");
      });

      this.route('insurance', function() {
        this.route('decision');
        this.route('review');
      });

      this.route('banking', function() {
        this.route('log-in');
        this.route('set-up-payments');
        this.route('also-set-up-payments');
        this.route('submit-proof-of-income');
        this.route('agreement');
        this.route('choose-account');
        this.route('connect');
        this.route('processing');
        this.route('none-found');
        this.route('error');
        return this.route('confirmation');
      });
    });

    this.route('funded');
    this.route('aborted');

    this.route('banking', function() {
      this.route('change-account');
      this.route('connect');
      this.route('processing');
      this.route('choose-account');
      this.route('none-found');
      this.route('error');
      this.route('confirmation');
    });

    this.route('payment-options', function() {
      this.route('change');
      this.route('error');
      this.route('review', function() {
        this.route('confirmation')
      });
    });

    this.route('payment', function() {
      this.route('payment-details');
      this.route('payout-statement');
      this.route('make-payment');
      this.route('review', function() {
        this.route('payment-confirmation')
      });
    });
  });

  this.route('404', { path: '*path' });
});
