import classic from 'ember-classic-decorator';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import PortalController from '../../../portal';

@classic
class PortalPaymentReviewController extends PortalController {
  queryParams = ['makePaymentType'];
  showSpinner = false;
  errorMessageCount = 0;

  @service
  intl;

  @service store

  @computed(
    'loan.currentArrears.total_overdue_payment',
    'model.changeset.makePaymentAmount'
  )
  get remainingOverdueAmount() {
    const { changeset } = this.model
    return (Math.max(0,this.get('loan.currentArrears.total_overdue_payment') - changeset.get('makePaymentAmount')))
  }

  @computed('model.changeset.makePaymentAmount')
  get paymentAmount() {
    const { changeset } = this.model
    return changeset.get('makePaymentAmount')
  }

  setAdditionalErrorMessage() {
    this.set('errorMessageCount', parseInt(this.errorMessageCount + 1));
    if (this.errorMessageCount >= 3) {
      const additional_message = this.intl.t('credit_card.additional_error_message', { phone_number: this.intl.t('financeit.phone'), email: this.intl.t('contact.feedback_contact_email') });
      return this.set('additionalErrorMessage', additional_message);
    } else {
      return this.set('additionalErrorMessage', '');
    }
  }

  @action
  async confirmationMakePaymentClick() {
    this.set('showSpinner', true)

    const data = await this.model.payment.borrowerMakePayment({
      paymentType: this.model.changeset.get('makePaymentType'),
      amount: this.model.changeset.get('makePaymentAmount'),
      payment_method: this.model.changeset.get('paymentMethod'),
      login_key: this.model.loginKey
    })

    this.store.pushPayload('loan', { loan: data.loan });

    this.set('showSpinner', false)
    window.scrollTo(0, 0);
    return this.transitionToRoute('portal.payment.review.payment-confirmation');
  }
}

export default PortalPaymentReviewController;
