import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import { onActivate, onDeactivate } from '../../../utils/step-transition'
import { transitionToDefaultRoute, checkAuthenticated } from '../../../utils/redirect-session'

export default class PortalHubInsuranceRoute extends Route {
  @service store
  @service router
  @service notification

  activate() {
    super.activate(...arguments)
    onActivate()
    this.notification.reset()
  }

  deactivate() {
    super.deactivate(...arguments)
    onDeactivate()
  }

  beforeModel(transition) {
    checkAuthenticated(transition, this)
    const requestFundsStep = this.store.peekAll('loan-step').findBy('name', 'request_funds')
    const insuranceOfferDecision = this.store.peekAll('loan-step').findBy('name', 'insurance_offer_decision')

    if (!requestFundsStep?.isCompleted || !(insuranceOfferDecision?.isNew || insuranceOfferDecision?.isAlerted)) {
      return transitionToDefaultRoute(this)
    } else if (!transition.targetName.endsWith('decision')) {
      return this.router.transitionTo('portal.hub.insurance.decision')
    }
  }

  async model() {
    const offer = await this._fetchInsuranceOffer()

    return {
      loanStep: this.store.peekAll('loan-step').findBy('name', 'insurance_offer_decision'),
      insuranceOffer: offer
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model)
  }

  async _fetchInsuranceOffer() {
    const json = await fetch(`/en/api/v3/direct/loans/${this.controllerFor('loan').get('model.id')}/insurance_offer`).then(response => response.json())
    this.store.pushPayload('insuranceOffer', { insuranceOffer: json.insurance_offer })

    return this.store.find('insuranceOffer', json.insurance_offer.id)
  }
}
